import request from '@/utils/request'
// 舆情来源网站统计
export function getWebStat (data) {
  return request({
    url: '/api/stat/getWebStat',
    method: 'POST',
    data
  })
}
export function getCarrDis (data) {
  return request({
    url: '/api/stat/getCarrDis',
    method: 'POST',
    data
  })
}
// 舆情态势统计图
export function mediaRanking (data) {
  return request({
    url: '/api/stat/getSituStatByHour',
    method: 'POST',
    data
  })
}
export function monitorStat (data) {
  return request({
    url: '/api/stat/monitorStat',
    method: 'POST',
    data
  })
}
export function markStatistics (data) {
  return request({
    url: '/api/stat/markStatistics',
    method: 'POST',
    data
  })
}
// 导出
export function dataOutExport (data) {
  return request({
    url: '/api/stat/dataOut',
    method: 'POST',
    data:data
  })
}
