<template>
  <section class="export-wrap">
    <h3 class="title">数据导出</h3>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="时间范围" class="time">
        <el-date-picker type="date" placeholder="开始时间" v-model="form.startDate" value-format="yyyy-MM-dd" @change="reloadMS"></el-date-picker>
        <span style="display: inline-block;margin: 0 10px;"> 至 </span>
        <el-date-picker type="date" placeholder="结束时间" v-model="form.endDate" value-format="yyyy-MM-dd" @change="reloadMS"></el-date-picker>
      </el-form-item>
      <el-form-item label="选择分类">
        <el-button :disabled="msDisabled"  v-loading="msLoading" size="medium" @click="showSelMs">点击选择分类</el-button>
        <div style="margin: 10px" v-if="selMS">
          <el-tree :check-strictly="true" :data="data" @check-change="modifyIds" show-checkbox node-key="id" ref="tree" style="padding: 10px;padding-right: 10px;background: #F7F6FB;border-radius: 18px" :expand-on-click-node="false">
                  <span class="custom-tree-node" slot-scope="{node, data}">
                    <span>{{ data.title }}</span>
                    <span style="color: dodgerblue">  ( {{data.count}} )</span>
                  </span>
          </el-tree>
        </div>
      </el-form-item>
      <el-form-item label="文件名">
        <el-input size="medium" v-model="form.fileName" style="width:50%"></el-input>

      </el-form-item>
      <el-form-item label="情感">
        <el-checkbox-group v-model="form.emotionList" @change="reloadMS2">
          <el-checkbox label="0">正面</el-checkbox>
          <el-checkbox label="1">负面</el-checkbox>
          <el-checkbox label="2">敏感</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item id="medium" label="媒体类型" prop="sourceList">
        <el-checkbox-group v-model="form.sourceList" v-loading="sourceLoading" @change="reloadMS2">
          <el-checkbox v-for="item in siteTypeList" :label="item.id" :name="item.siteName" :key="item.id">{{ item.siteName }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="已选分类" v-if="tags.length>0">
        <el-tag
          style="margin-right:10px"
          v-for="tag in tags"
          :key="tag.id"
          closable
          :type="tag.type" @close="removeNode(tag.id,tag.name)">
          {{tag.name}}
        </el-tag>
      </el-form-item>
      <!-- <el-form-item label="维度">
        <el-checkbox-group v-model="form.weidu">
          <el-checkbox label="1" name="type">来源</el-checkbox>
          <el-checkbox label="2" name="type">正负面</el-checkbox>
        </el-checkbox-group>
      </el-form-item> -->
      <el-form-item>
        <el-button size="mini" @click="$router.go(-1)">返回</el-button>
        <el-button size="mini" type="primary" @click="onSubmit">导出</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { selectAllType2 } from '@/api/dataOutput'
import { dataOutExport } from '@/api/dimension'
import { getToken } from '@/utils/auth'
import { apiBaseURL } from '@/utils/request'
import { listAllSiteType } from '@/api/detection'
export default {
  data () {
    return ({
      msLoading: false,
      msDisabled: true,
      sourceLoading: true,
      selMS: false,
      data: [],
      form: {
        projectName: '',
        startDate: '',
        endDate: '',
        fileName:'',
        delivery: false,
        weidu: ['1', '2'],
        msIds: [],
        desc: '',
        emotionList: ['0', '1', '2'],
        sourceList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      },
      tags: [],
      siteTypeList: []
    })
  },
  methods: {
    async reloadMS () {
      if (this.form.startDate !== null && this.form.startDate !== undefined && this.form.startDate !== '' && this.form.endDate !== null && this.form.endDate !== undefined && this.form.endDate !== '') {
        this.allMS()
        this.msDisabled = false
      }
    },
    async reloadMS2 () {
      this.allMS()
    },
    async listSiteType () {
      const res = await listAllSiteType()
      this.siteTypeList = res.data
      if (res.code === 0) {
        this.sourceLoading = false
      }
    },
    async allMS () {
      this.msLoading = true
      const res = await selectAllType2(this.form)
      this.data = res.data
      if (res.code === 0) {
        this.msLoading = false
      }
    },
    modifyIds (item) {
      var selected = this.$refs.tree.getCheckedNodes()
      this.tags = []
      for (var j = 0; j < selected.length; j++) {
        var tag = {}
        tag.name = selected[j].title
        tag.id = selected[j].id
        this.tags.push(tag)
      }
    },
    removeNode (id, name) {
      for (var i = 0; i < this.tags.length; i++) {
        if (id === this.tags[i].id) {
          this.tags.splice(i, 1)
        }
      }
    },
    showSelMs () {
      this.selMS = true
    },
    async onSubmit () {
      if (this.form.startDate === '' || this.form.endDate === '') {
        this.$message.warning("请选择时间范围")
        return
      }
      for (var i = 0; i <= this.tags.length - 1; i++) {
        this.form.msIds.push(this.tags[i].id)
      }
      if (this.form.msIds.length === 0) {
        this.$message.warning("请选择需要导出的分类")
        return
      }
      if (this.form.fileName === "") {
        this.$message.warning("请输入导出文件名称")
        return
      }
      try {
        let token = getToken()
        var res = await dataOutExport({fileName:this.form.fileName,msIds:this.form.msIds.join(','),startDate:this.form.startDate,endDate:this.form.endDate,weidu:this.form.weidu.join(','),emotionList:this.form.emotionList,sourceType:this.form.sourceList,token:token})
        this.form.msIds = []
        if(res.code == 0){
          this.$message.success(res.msg)
        }
      } catch (error) {
        this.$message.error('服务器内部异常')
      }
      this.listSN = []
    }
  },
  mounted () {
    // this.allMS()
    this.listSiteType()
  }
}
</script>

<style lang="scss">
.export-wrap {
  width: 100%;
  min-height: 100%;
  background: #fff;
  box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
  border-radius:10px;
  padding: 25px;
  .title {
    font-size: 14px;
    color: #606266;
  }
  .time {
    .el-form-item__content {
      display: flex;
    }
  }
}
</style>
